import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  ThemeProvider,
} from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { Plus } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DELETEAPIAdmin, GetAPIAdmin, POSTAPI_ADMIN } from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  getMuiTheme,
  InputComp,
  LabelComp,
  RedSwitch,
  TableEditDelete,
  TopBackComp,
} from "../../Components/Common";
import { ACTIONS, ACTIONS_ARR, ENTITY_TYPE } from "../../Components/staticData";
import { isTextEmpty } from "../../Components/Validate_Admin";
import "../../css/Admin/employee.css";
import "../../css/Admin/item.css";

export const Designation = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    designations: [],
    itemModalVisible: false,
    des: "",
    designationId: "",
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    designations,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/designation/").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            designations: res.designations,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "numberOfEmployees",
      headerName: "No. of Employees",
      width: 170,
    },
    {
      field: "icon",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        return (
          <TableEditDelete
            onClick={(e) => e.stopPropagation()}
            id={params.row.designationId}
            EditFn={EditFn}
            DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];

  const EditFn = () => {
    var item = designations.filter((x) => x.designationId === edClicked);
    if (item.length === 1) {
      item = item[0];
      navigate("/hr/designations/one", { state: item });
    }
  };

  const DeleteFn = () => {
    var item = designations.filter((x) => x.designationId === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/designation/remove", { designationId: edClicked }).then(
        (res) => {
          if (res.success) {
            setValues((x) => ({
              ...x,
              errorPosition: "success",
              errorText: `Designation ${item.title} deleted`,
              designations: res.designations,
            }));
          } else if (res.error)
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error,
            }));
        },
      );
    }
  };

  const handleRowClick = (item) => {
    navigate("/hr/designations/one", { state: item });
  };

  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No designations yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };
  return (
    <div className="category_body">
      <div className="category_top">
        <span className="category_top_title">Designation</span>
        <Button
          onClick={() => navigate("/hr/designations/one")}
          className="each_top_btn"
        >
          <Plus className="mr-1" color="#fff" weight="bold" />
          Add Designation
        </Button>
      </div>

      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    data={designations}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <div style={{ height: "65vh", width: "420px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={designations}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row.designationId}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddEditDesignation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [values, setValues] = useState({
    des: stateData ? stateData.title : "",
    designationId: stateData ? stateData.designationId : "",
    des_loading: false,
    errorPosition: "",
    errorText: "",
    allowKDS: stateData ? stateData.allowKDS : false,
    allowRiderApp: stateData ? stateData.allowRiderApp : false,
    allowAdminPanel: stateData ? stateData.allowAdminPanel : false,
    allowCsrPanel: stateData ? stateData.allowCsrPanel : false,
    actionsOptions1: [
      { name: "Create Own", value: ACTIONS.CREATE_OWN },
      { name: "Create All", value: ACTIONS.CREATE_ALL },
      { name: "Read Own", value: ACTIONS.READ_OWN },
      { name: "Read All", value: ACTIONS.READ_ALL },
    ],
    actionsOptions2: [
      { name: "Update Own", value: ACTIONS.UPDATE_OWN },
      { name: "Update All", value: ACTIONS.UPDATE_ALL },
      { name: "Delete Own", value: ACTIONS.DELETE_OWN },
      { name: "Delete All", value: ACTIONS.DELETE_ALL },
    ],
    allResources: [
      ENTITY_TYPE.APP,
      ENTITY_TYPE.ATTENDANCE,
      ENTITY_TYPE.BRAND,
      ENTITY_TYPE.CART,
      ENTITY_TYPE.CATEGORY,
      ENTITY_TYPE.COOKING_STATS,
      ENTITY_TYPE.COMBO,
      ENTITY_TYPE.DASHBOARD,
      ENTITY_TYPE.DESIGNATION,
      ENTITY_TYPE.EMPLOYEE,
      ENTITY_TYPE.HOMEPAGE,
      ENTITY_TYPE.INVENTORY,
      ENTITY_TYPE.ITEM,
      ENTITY_TYPE.LOCATION,
      ENTITY_TYPE.MARKETPLACE,
      ENTITY_TYPE.MEMBERSHIP,
      ENTITY_TYPE.MODIFIER,
      ENTITY_TYPE.NOTIFICATION,
      ENTITY_TYPE.OFFER,
      ENTITY_TYPE.ORDER,
      ENTITY_TYPE.ORDER_STATS,
      ENTITY_TYPE.REPORT,
      ENTITY_TYPE.RIDER_STATS,
      ENTITY_TYPE.SETTING,
      ENTITY_TYPE.SHIFT,
      ENTITY_TYPE.SUBSCRIPTION, 
      ENTITY_TYPE.USER, 
    ],
    permissions:
      stateData && stateData.designationId
        ? stateData.permissions
        : ACTIONS_ARR,
  });
  const {
    des,
    designationId,
    des_loading,
    errorPosition,
    errorText,
    allowKDS,
    allowRiderApp,
    allowAdminPanel,
    allowCsrPanel,
    actionsOptions1,
    actionsOptions2,
    allResources,
    permissions,
  } = values;

  const addDesignation = () => {
    var send_data = {
      title: des,
      allowKDS,
      allowRiderApp,
      allowAdminPanel,
      allowCsrPanel,
      permissions,
    };
    if (des_loading) return;
    if (isTextEmpty(des)) {
      setValues((x) => ({
        ...x,
        errorPosition: "des",
        errorText: "Invalid value",
      }));
      return;
    }
    setValues((x) => ({
      ...x,
      errorPosition: "",
      errorText: "",
      des_loading: true,
    }));
    if (isTextEmpty(designationId)) {
      //add
      POSTAPI_ADMIN("/designation/create", send_data).then((res) => {
        if (res.success) {
          navigate("/hr/designations");
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            des_loading: false,
          }));
      });
    } else {
      //edit
      send_data.designationId = designationId;
      POSTAPI_ADMIN("/designation/edit", send_data).then((res) => {
        if (res.success) {
          navigate("/hr/designations");
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            des_loading: false,
          }));
      });
    }
  };

  const handleCheckbox = (i, eachAction) => {
    var nowActionsArr = [...permissions];
    var changedVal = [...nowActionsArr[i]?.actions];
    if (changedVal.includes(eachAction.value)) {
      var indOf = changedVal.indexOf(eachAction.value);
      changedVal.splice(indOf, 1);
    } else {
      changedVal.push(eachAction.value);
    }
    nowActionsArr[i].actions = changedVal;
    setValues((x) => ({ ...x, permissions: nowActionsArr }));
  };

  return (
    <>
      {TopBackComp("Add Designation", "/hr/designations")}
      <div className="add_desig_body">
        {LabelComp("Designation Title", "des", errorPosition, errorText)}
        {InputComp(des, "des", setValues, "add_category_input_full w-75 mb-4")}

        <div className="d-flex justify-content-between w-75">
          <span>KDS</span>
          <RedSwitch
            checked={allowKDS}
            onChange={() => setValues((x) => ({ ...x, allowKDS: !allowKDS }))}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
        <div className="d-flex justify-content-between w-75">
          <span>Rider App</span>
          <RedSwitch
            checked={allowRiderApp}
            onChange={() =>
              setValues((x) => ({ ...x, allowRiderApp: !allowRiderApp }))
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
        <div className="d-flex justify-content-between w-75">
          <span>Admin Panel</span>
          <RedSwitch
            checked={allowAdminPanel}
            onChange={() =>
              setValues((x) => ({ ...x, allowAdminPanel: !allowAdminPanel }))
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
        <div className="d-flex justify-content-between w-75">
          <span>CSR Panel</span>
          <RedSwitch
            checked={allowCsrPanel}
            onChange={() =>
              setValues((x) => ({ ...x, allowCsrPanel: !allowCsrPanel }))
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>

        {allResources.map((each, i) => {
          return (
            <div key={i}>
              <p className="mt-2">{each}</p>
              <div className="d-flex justify-content-between">
                {actionsOptions1.map((eachAction, ind) => {
                  return (
                    <FormControlLabel
                      className="adb_form"
                      key={ind}
                      control={
                        <Checkbox
                          checked={permissions[i]?.actions.includes(
                            eachAction.value,
                          )}
                          onChange={() => handleCheckbox(i, eachAction)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#8943EF",
                              borderRadius: "20px",
                            },
                            "& .MuiSvgIcon-root": { fontSize: "18px" },
                          }}
                        />
                      }
                      label={<span className="s1">{eachAction.name}</span>}
                    />
                  );
                })}
              </div>
              <div className="d-flex justify-content-between">
                {actionsOptions2.map((eachAction, ind) => (
                  <FormControlLabel
                    className="adb_form"
                    key={ind}
                    control={
                      <Checkbox
                        checked={permissions[i]?.actions.includes(
                          eachAction.value,
                        )}
                        onChange={() => handleCheckbox(i, eachAction)}
                        sx={{
                          "&.Mui-checked": {
                            color: "#8943EF",
                            borderRadius: "20px",
                          },
                          "& .MuiSvgIcon-root": { fontSize: "18px" },
                        }}
                      />
                    }
                    label={<span className="s1">{eachAction.name}</span>}
                  />
                ))}
              </div>
            </div>
          );
        })}

        <Button className="adesig_btn mt-3" onClick={() => addDesignation()}>
          {des_loading ? (
            <CircularProgress style={{ height: "40px", color: "#F7F4DB" }} />
          ) : (
            <>
              {isTextEmpty(designationId)
                ? "Add Designation"
                : "Update Designation"}
            </>
          )}
        </Button>
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
