import { API, API_KEY } from "../Auth/Config";
import { isAuthenticated, isAuthenticatedAdmin } from "../Auth/Index";
import { apiSlice } from "../api/apiSlice";

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHome: builder.query({
      query: () => ({
        url: `${API}/homepage${isAuthenticated() ? `/${isAuthenticated()?.userId}` : ""}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${isAuthenticated()?.jwtToken}`,
        },
      }),
      providesTags: ["Homepage"],
    }),
    filter: builder.mutation({
      query: (initialPost) => ({
        url: `${API}/homepage/filter/items`,
        method: "POST",
        body: { ...initialPost },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${isAuthenticated()?.jwtToken}`,
        },
      }),
    }),
    // admin
    getDashboard: builder.query({
      query: (query) => ({
        url: `${API}/dashboard?dateFilterType=custom&${query}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${isAuthenticated()?.jwtToken}`,
        },
      }),
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }),
    getDashboardItems: builder.query({
      query: (query) => ({
        url: `${API}/dashboard/items?dateFilterType=custom&${query}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${isAuthenticated()?.jwtToken}`,
        },
      }),
    }),
    getDashboardCus: builder.query({
      query: (query) => ({
        url: `${API}/dashboard/customers?dateFilterType=custom&${query}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${isAuthenticated()?.jwtToken}`,
        },
      }),
    }),
    getOrderStats: builder.query({
      query: (query) => ({
        url: `${API}/dashboard/orders?dateFilterType=custom&${query}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${isAuthenticatedAdmin()?.jwtToken}`,
        },
      }),
    }),
    uploadMarketplaceImages: builder.mutation({
      query: (body) => ({
        url: `${API}/settings/images`,
        method : 'POST',
        body : body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${isAuthenticatedAdmin()?.jwtToken}`,
        },
      }),
    }),
    getBannerImages: builder.query({
      query: (query) => ({
        url: `${API}/settings/webBannerImages`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      }),
    }),
    getPopupImages: builder.query({
      query: (query) => ({
        url: `${API}/settings/webPopupImages`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      }),
    }),
  }),
});

export const {
  useGetHomeQuery,
  useFilterMutation,
  useGetDashboardQuery,
  useGetDashboardItemsQuery,
  useGetDashboardCusQuery,
  useGetOrderStatsQuery,
  useUploadMarketplaceImagesMutation,
  useGetBannerImagesQuery,
  useGetPopupImagesQuery
} = customerApiSlice;
