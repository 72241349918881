import {
  Avatar,
  Checkbox,
  FormControlLabel,
  ThemeProvider,
} from "@mui/material";
import { DatePicker } from "antd";
// import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  DELETEAPIAdmin,
  GetAPIAdmin,
  POSTAPI_CreateInfoAdmin,
  POSTAPI_formAdmin,
  PUT_API_formAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  CircularProgressComp,
  DataGridNova,
  getMuiTheme,
  HalfRightBtnComp,
  InputComp,
  InputComp_Drop,
  InputComp_Num,
  LabelComp,
  ReactSelectDrop,
  ReactSelectDropMulti,
  SelectImage_Small,
  TableEditDelete,
  TopAddComp,
  TopBackComp,
} from "../../Components/Common";
import MySelect, {
  MultiValue,
  OptionComp,
} from "../../Components/OffersSelectComp";
import { offerGroupValues, offerTypes } from "../../Components/staticData";
import { ValidateOffers } from "../../Components/Validate_Admin";
import "../../css/Admin/category.css";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const animatedComponents = makeAnimated();

export const Offer = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    offers: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    offers,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/offer/").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            offers: res.offers,
            first: false,
            loading: false,
          }));
        }
      });
    }
  }, [first]);

  // const columns = [
  //     {
  //         name: '_id',
  //         label: '',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: "name",
  //         label: "Offer Name",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 var data = offers[tableMeta.rowIndex]
  //                 return (
  //                     <div className="d-flex align-items-center">
  //                         <Avatar style={{ borderRadius: '12px' }} src={data.image} />
  //                         <span className="ml-3">{data.name}</span>
  //                     </div>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "type",
  //         label: "type",
  //         options: {
  //             filter: true,
  //             sort: true,
  //         }
  //     },
  //     {
  //         name: "amount",
  //         label: "Amount",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <>Rs. {value}</>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "group",
  //         label: "Applied on",
  //         options: {
  //             filter: true,
  //             sort: true,
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete onClick={(e) => e.stopPropagation()} id={tableMeta.rowData[0]}
  //                         EditFn={EditFn} DeleteFn={DeleteFn}
  //                         edAnchor={edAnchor} setValues={setValues} edClicked={edClicked} />
  //                 )
  //             }
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No offers yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  const EditFn = () => {
    var item = offers.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      navigate("/offers/edit", { state: item });
    }
  };

  const DeleteFn = () => {
    var item = offers.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/offer/remove", { offerId: edClicked }).then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            errorPosition: "success",
            errorText: `Offer ${item.name} deleted`,
            offers: res.offers,
          }));
        } else if (res.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  const handleRowClick = (item) => {
    navigate("/offers/edit", { state: item });
  };

  const columnsData = [
    {
      field: "name",
      headerName: "Offer name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      renderCell: (params) => {
        return (
          <span>
            {params.row.symbol === "%" ? "" : "Rs."}
            {params.value}
            {params.row.symbol === "%" && "%"}
          </span>
        );
      },
    },
    {
      field: "group",
      headerName: "Applied On",
      width: 120,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "status",
      headerName: "",
      description: "This column is sortable",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <TableEditDelete
            onClick={(e) => e.stopPropagation()}
            id={params.row._id}
            EditFn={EditFn}
            DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];

  return (
    <div className="category_body">
      {TopAddComp("Offer", "/offers/add", "Add Offer")}

      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    data={offers}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <div style={{ height: "65vh", width: "750px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={offers}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddOffer = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    type: "",
    promoCode: "",
    promoLimit: "",
    amount: "",
    discountType: "percent",
    from: dayjs(),
    to: dayjs(),
    group: "",
    groupValues: [],
    groupValuesDiv: [],
    image: null,
    groupArrs: {
      item: [],
      category: [],
      brand: [],
      division: [],
    },
    first: true,
    loading: false,
    errorPosition: "",
    errorText: "",
    isSendNotification: true,
  });
  const {
    name,
    type,
    promoCode,
    promoLimit,
    amount,
    discountType,
    from,
    to,
    group,
    groupValues,
    groupValuesDiv,
    image,
    groupArrs,
    loading,
    first,
    errorPosition,
    errorText,
    isSendNotification,
  } = values;

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            groupArrs: {
              item: res.items,
              category: res.categories,
              brand: res.brands,
              division: [],
            },
            first: false,
          }));
        }
      });
    }
  }, [first]);

  const getGroupLabel = () => {
    let val;
    switch (group) {
      case "Category":
        val = "Category";
        break;
      case "All":
        val = "All";
        break;
      case "Item":
        val = "Item";
        break;
      case "Brand":
        val = "Brand";
        break;
      case "Division":
        val = "Brand";
        break;
      default:
        val = "";
    }
    return val;
  };

  const getGroupValueArr = () => {
    let val;
    switch (group) {
      case "Category":
        val = groupArrs.category;
        break;
      case "Item":
        val = groupArrs.item;
        break;
      case "Brand":
        val = groupArrs.brand;
        break;
      case "Division":
        val = groupArrs.brand;
        break;
      default:
        val = [];
    }
    return val;
  };

  const getDivision = () => {
    var brand = groupArrs.brand.filter((x) => x._id === groupValues);
    var division = [];
    if (brand.length > 0) {
      division = brand[0].divisions;
    }
    division = division.map((x) => ({ _id: x.divisionId, name: x.name }));
    return division;
  };

  const handleCreate = async () => {
    if (loading) return;
    var validated = await ValidateOffers(
      name,
      type,
      promoCode,
      amount,
      promoLimit,
      from,
      to,
      group,
      groupValues,
      groupValuesDiv,
      image,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({ ...prev, loading: true }));
      var formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      formData.append("type", type);
      formData.append("promoCode", promoCode);
      formData.append("promoLimit", promoLimit);
      formData.append("amount", amount);
      formData.append("discountType", discountType);
      formData.append("from", from);
      formData.append("to", to);
      formData.append("group", group);
      formData.append("isSendNotification", isSendNotification);
      if (group === "Division") {
        formData.append("groupValues", JSON.stringify(groupValues));
        var nowGroupValuesDiv = [...groupValuesDiv];
        var arrDiv = [];
        nowGroupValuesDiv.filter((x) => x._id !== "*" && arrDiv.push(x._id));
        formData.append("groupValuesDiv", JSON.stringify(arrDiv));
      } else {
        var nowGroupValues = [...groupValues];
        var arr = [];
        nowGroupValues.filter((x) => arr.push(x._id));
        formData.append("groupValues", JSON.stringify(arr));
      }
      POSTAPI_formAdmin("/offer/create", formData).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            errorPosition: "success",
            first: false,
            errorText: "Offer created",
          }));
          navigate("/offers");
        }
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  const onChangeDate = (date, dateString) => {
    setValues((prev) => ({ ...prev, from: dateString[0], to: dateString[1] }));
  };

  const handleDiv = (e) => {
    var newVal = e.map((item) =>
      item.label ? { _id: item.value, name: item.label } : item,
    );
    setValues((mainValues) => ({
      ...mainValues,
      groupValuesDiv: newVal,
      allDivsSelected: false,
    }));
  };

  return (
    <>
      {TopBackComp("Add Offer", "/offers")}

      <div className="add_brand_body">
        <div className="add_brand_body_top">
          <div className="brand_left">
            {LabelComp("Offer Name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "add_category_input")}

            {LabelComp("Type", "type", errorPosition, errorText)}
            {ReactSelectDrop(type, "type", setValues, offerTypes)}

            {type === "Promo" && (
              <>
                {LabelComp("Promo Code", "promoCode", errorPosition, errorText)}
                {InputComp(
                  promoCode,
                  "promoCode",
                  setValues,
                  "add_category_input",
                )}
              </>
            )}

            {(type === "Promo" || type === "Offer") && (
              <>
                {LabelComp("Amount", "amount", errorPosition, errorText)}
                {InputComp_Drop(
                  amount,
                  "amount",
                  setValues,
                  discountType,
                  "discountType",
                )}

                {LabelComp("Validity", "validity", errorPosition, errorText)}
                <RangePicker
                  onChange={onChangeDate}
                  style={{
                    borderRadius: "12px",
                    borderColor: "#818091",
                    height: "40px",
                  }}
                  value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
                  format={dateFormat}
                />
              </>
            )}

            {type === "Promo" && (
              <>
                {LabelComp(
                  "Promo Limit (users)",
                  "promoLimit",
                  errorPosition,
                  errorText,
                )}
                {InputComp_Num(
                  promoLimit,
                  "promoLimit",
                  setValues,
                  "add_category_input",
                )}
              </>
            )}

            {type === "Offer" && (
              <>
                {LabelComp(
                  "Banner Image (16:9 ratio)",
                  "image",
                  errorPosition,
                  errorText,
                  "mt-2",
                )}
                {SelectImage_Small(image, "image", setValues)}
              </>
            )}
          </div>

          <div className="brand_left">
            {LabelComp("Apply Offer on", "group", errorPosition, errorText)}
            <Select
              maxMenuHeight={"200px"}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              options={offerGroupValues.map((item) =>
                item._id ? { label: item.name, value: item._id } : item,
              )}
              value={offerGroupValues
                .filter((option) => option._id === group)
                .map((item) => ({ label: item.name, value: item._id }))}
              name="group"
              onChange={(e) =>
                setValues((mainValues) => ({
                  ...mainValues,
                  group: e.value,
                  groupValues: [],
                  groupValuesDiv: [],
                  allDivsSelected: false,
                }))
              }
            />

            {(group === "Category" ||
              group === "Item" ||
              group === "Brand") && (
              <>
                {LabelComp(
                  `${getGroupLabel()}`,
                  "groupValues",
                  errorPosition,
                  errorText,
                )}
                {ReactSelectDropMulti(
                  groupValues,
                  "groupValues",
                  setValues,
                  getGroupValueArr(),
                )}
              </>
            )}

            {group === "Division" && (
              <>
                {LabelComp("Brand", "groupValues", errorPosition, errorText)}
                <Select
                  maxMenuHeight={"200px"}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={getGroupValueArr().map((item) =>
                    item._id ? { label: item.name, value: item._id } : item,
                  )}
                  value={getGroupValueArr()
                    .filter((option) => option._id === groupValues)
                    .map((item) => ({ label: item.name, value: item._id }))}
                  name="groupValues"
                  onChange={(e) =>
                    setValues((mainValues) => ({
                      ...mainValues,
                      groupValues: e.value,
                      groupValuesDiv: [],
                      allDivsSelected: false,
                    }))
                  }
                />

                {LabelComp(
                  "Division",
                  "groupValuesDiv",
                  errorPosition,
                  errorText,
                )}
                <MySelect
                  options={getDivision().map((item) =>
                    item.name ? { label: item.name, value: item._id } : item,
                  )}
                  value={groupValuesDiv.map((item) =>
                    item.name ? { label: item.name, value: item._id } : item,
                  )}
                  isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={true}
                  components={{ OptionComp, MultiValue, animatedComponents }}
                  onChange={(e) => handleDiv(e)}
                  allowSelectAll={true}
                />
              </>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  checked={isSendNotification}
                  onChange={(event) =>
                    setValues((x) => ({
                      ...x,
                      isSendNotification: event.target.checked,
                    }))
                  }
                  sx={{
                    color: "#8943EF",
                    "&.Mui-checked": {
                      color: "#8943EF",
                    },
                  }}
                />
              }
              label="Send Notification"
            />
          </div>
        </div>
        {HalfRightBtnComp("Add Offer", handleCreate, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditOffer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state) navigate("/brands");

  const [values, setValues] = useState({
    name: "",
    type: "",
    promoCode: "",
    promoLimit: "",
    amount: "",
    discountType: "percent",
    from: new Date(),
    to: new Date(),
    group: "",
    groupValues: [],
    groupValuesDiv: [],
    image: null,
    groupArrs: {
      item: [],
      category: [],
      brand: [],
      division: [],
    },
    first: true,
    firstLoading: true,
    loading: false,
    errorPosition: "",
    errorText: "",
  });
  const {
    name,
    type,
    promoCode,
    promoLimit,
    amount,
    discountType,
    from,
    to,
    group,
    groupValues,
    groupValuesDiv,
    image,
    groupArrs,
    firstLoading,
    loading,
    first,
    errorPosition,
    errorText,
  } = values;

  var stateData = location.state;

  const getFilterArr = (res) => {
    let val;
    switch (stateData.group) {
      case "Category":
        val = res.categories;
        break;
      case "Item":
        val = res.items;
        break;
      case "Brand":
        val = res.brands;
        break;
      case "All":
        val = [...res.categories, ...res.items, ...res.brands];
        break;
      default:
        val = [];
    }
    return val ? val : [];
  };

  const valuesFn = async (res) => {
    var groupValuesX = "";
    var groupValuesDivX = [];
    if (stateData.group === "Division") {
      groupValuesX = location.state.groupValues;
      var thisItem = res.brands.filter((x) => x._id === stateData.groupValues);
      var thisItemDivs = thisItem[0].divisions;
      var originalDiv = location.state.groupValuesDiv;
      originalDiv.forEach((element) => {
        var foundDiv = thisItemDivs.filter((x) => x.divisionId === element);
        foundDiv[0] && groupValuesDivX.push(foundDiv[0]);
      });
    } else {
      groupValuesX = [];
      var original = location.state.groupValues;
      var filterArr = await getFilterArr(res);
      original?.forEach((element) => {
        var found = filterArr.filter((x) => element === x._id);
        found[0] && groupValuesX.push(found[0]);
      });
    }
    setValues((prev) => ({
      groupArrs: {
        item: res.items,
        category: res.categories,
        brand: res.brands,
        division: [],
      },
      name: stateData.name,
      type: stateData.type,
      promoCode: stateData.promoCode,
      groupValues: groupValuesX,
      firstLoading: false,
      promoLimit: stateData.promoLimit,
      amount: stateData.amount,
      discountType: stateData.discountType,
      from: dayjs(stateData.from),
      to: dayjs(stateData.to),
      group: stateData.group,
      groupValuesDiv: groupValuesDivX,
      image: stateData.image,
    }));
  };

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoAdmin().then((res) => {
        setValues((prev) => ({
          ...prev,
          first: false,
        }));
        if (res.success) {
          valuesFn(res);
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            firstLoading: false,
          }));
      });
    }
    // eslint-disable-next-line
  }, [first]);

  const handleUpdate = async () => {
    if (loading) return;
    var validated = await ValidateOffers(
      name,
      type,
      promoCode,
      amount,
      promoLimit,
      from,
      to,
      group,
      groupValues,
      groupValuesDiv,
      image,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({ ...prev, loading: true }));
      var formData = new FormData();
      formData.append("offerId", stateData._id);
      formData.append("name", name);
      formData.append("image", image);
      formData.append("type", type);
      formData.append("promoCode", promoCode);
      formData.append("promoLimit", promoLimit);
      formData.append("amount", amount);
      formData.append("discountType", discountType);
      formData.append("from", from);
      formData.append("to", to);
      formData.append("group", group);
      if (group === "Division") {
        formData.append("groupValues", JSON.stringify(groupValues));
        var nowGroupValuesDiv = [...groupValuesDiv];
        var arrDiv = [];
        nowGroupValuesDiv.filter(
          (x) => x._id !== "*" && arrDiv.push(x._id ? x._id : x.divisionId),
        );
        formData.append("groupValuesDiv", JSON.stringify(arrDiv));
      } else {
        var nowGroupValues = [...groupValues];
        var arr = [];
        nowGroupValues.filter((x) => arr.push(x._id));
        formData.append("groupValues", JSON.stringify(arr));
      }
      PUT_API_formAdmin("/offer/edit", formData).then((res) => {
        if (res.success) navigate("/offers");
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  const getGroupLabel = () => {
    let val;
    switch (group) {
      case "Category":
        val = "Category";
        break;
      case "Item":
        val = "Item";
        break;
      case "All":
        val = "All";
        break;
      case "Brand":
        val = "Brand";
        break;
      case "Division":
        val = "Brand";
        break;
      default:
        val = "";
    }
    return val;
  };

  const getGroupValueArr = () => {
    let val;
    switch (group) {
      case "Category":
        val = groupArrs.category;
        break;
      case "Item":
        val = groupArrs.item;
        break;
      case "Brand":
        val = groupArrs.brand;
        break;
      case "Division":
        val = groupArrs.brand;
        break;
      default:
        val = [];
    }
    return val ? val : [];
  };

  const getDivision = () => {
    var brand = groupArrs.brand.filter((x) => x._id === groupValues);
    var division = [];
    if (brand.length > 0) {
      division = brand[0].divisions;
    }
    division = division.map((x) => ({ _id: x.divisionId, name: x.name }));
    return division;
  };

  const onChangeDate = (date, dateString) => {
    setValues((prev) => ({ ...prev, from: dateString[0], to: dateString[1] }));
  };

  const handleDiv = (e) => {
    var newVal = e.map((item) =>
      item.label ? { _id: item.value, name: item.label } : item,
    );
    setValues((mainValues) => ({
      ...mainValues,
      groupValuesDiv: newVal,
      allDivsSelected: false,
    }));
  };

  return (
    <div className="d-flex flex-column align-items-start">
      {TopBackComp("Edit Offer", "/offers")}
      {firstLoading ? 
      <div className="d-flex w-100 justify-content-center">
        <CircularProgressComp />
        </div> : (
        <>
          <div className="add_brand_body">
            <div className="add_brand_body_top">
              <div className="brand_left">
                {LabelComp("Offer Name", "name", errorPosition, errorText)}
                {InputComp(name, "name", setValues, "add_category_input")}

                {LabelComp("Type", "type", errorPosition, errorText)}
                {ReactSelectDrop(type, "type", setValues, offerTypes)}

                {type === "Promo" && (
                  <>
                    {LabelComp(
                      "Promo Code",
                      "promoCode",
                      errorPosition,
                      errorText,
                    )}
                    {InputComp(
                      promoCode,
                      "promoCode",
                      setValues,
                      "add_category_input",
                    )}
                  </>
                )}

                {(type === "Promo" || type === "Offer") && (
                  <>
                    {LabelComp("Amount", "amount", errorPosition, errorText)}
                    {InputComp_Drop(
                      amount,
                      "amount",
                      setValues,
                      discountType,
                      "discountType",
                    )}

                    {LabelComp(
                      "Validity",
                      "validity",
                      errorPosition,
                      errorText,
                    )}
                    <RangePicker
                      onChange={onChangeDate}
                      style={{
                        borderRadius: "12px",
                        borderColor: "#818091",
                        height: "40px",
                      }}
                      value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
                      format={dateFormat}
                    />
                  </>
                )}

                {type === "Promo" && (
                  <>
                    {LabelComp(
                      "Promo Limit (users)",
                      "promoLimit",
                      errorPosition,
                      errorText,
                    )}
                    {InputComp_Num(
                      promoLimit,
                      "promoLimit",
                      setValues,
                      "add_category_input",
                    )}
                  </>
                )}

                {LabelComp(
                  "Banner Image (800*400)",
                  "image",
                  errorPosition,
                  errorText,
                  "mt-2",
                )}
                {SelectImage_Small(image, "image", setValues)}
              </div>

              <div className="brand_left">
                {LabelComp("Apply Offer on", "group", errorPosition, errorText)}
                <Select
                  maxMenuHeight={"200px"}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={offerGroupValues.map((item) =>
                    item._id ? { label: item.name, value: item._id } : item,
                  )}
                  value={offerGroupValues
                    .filter((option) => option._id === group)
                    .map((item) => ({ label: item.name, value: item._id }))}
                  name="group"
                  onChange={(e) =>
                    setValues((mainValues) => ({
                      ...mainValues,
                      group: e.value,
                      groupValues: [],
                      groupValuesDiv: [],
                      allDivsSelected: false,
                    }))
                  }
                />

                {(group === "Category" ||
                  group === "Item" ||
                  group === "Brand") && (
                  <>
                    {LabelComp(
                      `${getGroupLabel()}`,
                      "groupValues",
                      errorPosition,
                      errorText,
                    )}
                    {ReactSelectDropMulti(
                      groupValues,
                      "groupValues",
                      setValues,
                      getGroupValueArr(),
                    )}
                  </>
                )}

                {group === "Division" && (
                  <>
                    {LabelComp(
                      "Brand",
                      "groupValues",
                      errorPosition,
                      errorText,
                    )}
                    <Select
                      maxMenuHeight={"200px"}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      options={getGroupValueArr().map((item) =>
                        item._id ? { label: item.name, value: item._id } : item,
                      )}
                      value={getGroupValueArr()
                        .filter((option) => option._id === groupValues)
                        .map((item) => ({ label: item.name, value: item._id }))}
                      name="groupValues"
                      onChange={(e) =>
                        setValues((mainValues) => ({
                          ...mainValues,
                          groupValues: e.value,
                          groupValuesDiv: [],
                          allDivsSelected: false,
                        }))
                      }
                    />

                    {LabelComp(
                      "Division",
                      "groupValuesDiv",
                      errorPosition,
                      errorText,
                    )}
                    <MySelect
                      options={getDivision().map((item) =>
                        item.name
                          ? { label: item.name, value: item._id }
                          : item,
                      )}
                      value={groupValuesDiv.map((item) =>
                        item.name
                          ? { label: item.name, value: item._id }
                          : item,
                      )}
                      isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={true}
                      components={{
                        OptionComp,
                        MultiValue,
                        animatedComponents,
                      }}
                      onChange={(e) => handleDiv(e)}
                      allowSelectAll={true}
                    />
                  </>
                )}
              </div>
            </div>
            {HalfRightBtnComp("Update Offer", handleUpdate, loading)}
          </div>

          {AlertError(errorPosition, errorText, setValues)}
        </>
      )}
    </div>
  );
};
