// Function to convert JSON to CSV
export const jsonToCsv = (jsonData, filename) => {
    const csvRows = [];
    // Get headers
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(','));
    // Loop over the rows
    for (const row of jsonData) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    const csvData = csvRows.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}