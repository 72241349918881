import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Menu as MUIMenu,
  MenuItem,
  Modal,
  Radio,
  Snackbar,
  Switch,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  CaretDoubleDown,
  CaretDoubleUp,
  CheckCircle,
  DotsThree,
  Eye,
  EyeSlash,
  MagnifyingGlass,
  Skull,
  Warning
} from "@phosphor-icons/react";
import { Dropdown, Menu } from "antd";
import { useState } from "react";
import Select from "react-select";
import { PRIORITY_LEVEL } from "./staticData";

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#000000",
    borderRadius: 0,
    border: "1px solid #545356",
    fontFamily: "Blender Pro thin",
    outline: "none",
    boxShadow: state.isFocused ? "none !important" : "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#333333" : "transparent",
    fontFamily: "Blender Pro thin",

    // border: "1px solid #545356",
    color: "white",
    borderRadius: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#000000",
    borderRadius: 0,
    marginTop: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    color: "white",
    fontFamily: "Blender Pro thin",
  }),
};

export const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiTypography-root": {
    marginLeft: "8px", // Adjust spacing between radio button and label
    fontFamily: "Blender Pro thin !important",
  },
  "& .MuiFormControlLabel-label": {
    fontFamily: "Blender Pro thin !important",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.2rem",
    color: "#ffffff",
  },
}));

export const CustomRadio = styled(Radio)(() => ({
  borderRadius: "0px",
  width: "0.9rem",
  height: "0.9rem",
  border: "1px solid #545356",
  position: "relative",
  marginLeft: "0.5rem",
  fontFamily: "Blender Pro thin",
  "&::before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    border: "5px solid #00F0FF",
    boxSizing: "border-box", // Include border in dimensions
    display: "none", // Initially, hide the square
  },
  "&.Mui-checked::before": {
    display: "block", // Show the square when checked
  },
  "& .MuiSvgIcon-root": {
    display: "none", // Hide the circular radio icon
  },
}));

export const CustomCheckbox = styled(Checkbox)(() => ({
  borderRadius: "0px",
  width: "0.9rem",
  height: "0.9rem",
  border: "1px solid #545356",
  position: "relative",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  "&::before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    border: "5px solid #00F0FF",
    boxSizing: "border-box",
    display: "none",
  },
  "&.Mui-checked::before": {
    display: "block",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
}));

export const CsrSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-track": {
    border: "none !important",
    width: "2.18rem !important",
    height: "1.12rem !important",
    borderRadius: "0px !important",
    backgroundColor: "#55586A ",
    opacity: 1,
  },

  "& .MuiSwitch-thumb": {
    border: "1px solid #55586A !important",
    width: "0.87rem !important",
    height: "0.87rem !important",
    backgroundColor: "#AEB0B9 !important",
    borderRadius: "0px !important",
    transform: "translateY(4.9px) translateX(3.5px) !important",
  },

  "& .Mui-checked .MuiSwitch-track": {
    backgroundColor: "#00F0FF ",
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "black !important",
    border: "1px solid #00F0FF !important",
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "#00F0FF !important",
        opacity: 1,
      },
    },
  },
}));

export const CsrModal = styled(Modal)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 240, 255, 0.25)",
    backdropFilter: "blur(4px)",
  },
  "& .MuiPaper-root": {
    borderRadius: "0",
    backgroundColor: "black",
    color: "white",
  },
}));

export const DataGridNovaCsr = styled(DataGrid)(({ theme }) => ({
  "&.MuiDataGrid-root": {
    fontFamily: "Blender Pro thin",
    fontWeight: "400",
    fontSize: "1.1rem",
    lineHeight: "1.35rem",
    color: "white !important",
    border: "1px solid #545356",
    outline: "0",
    borderRadius: "0px",
    padding: "0 !important",
    margin: "0 !important",
    backgroundColor: "black !important",
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'black !important',
  },
  '& .MuiTablePagination-toolbar': {
    backgroundColor: 'black !important',
  },
  "& .MuiDataGrid-overlay": {
    backgroundColor: "black !important",
  },
  ".MuiDataGrid-columnHeaders": {
    height: "3.37rem !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden !important",
    borderBottom: "1px solid #545356",
    padding: "0 !important",
    margin: "0 !important",
    backgroundColor: "black !important",
  },
  ".MuiDataGrid-columnHeader": {
    padding: "0 !important",
    margin: "0 !important",
  },
  ".MuiDataGrid-columnHeaderTitle": {
    color: "#FFFFFF",
    fontWeight: "400",
    fontSize: "1.1rem",
    lineHeight: "1.35rem",
  },
  ".MuiDataGrid-footerContainer": {
    borderTop: "1px solid #545356",
    backgroundColor: "black !important",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    height: "3.37rem",
    padding: "0 8px",
  },
  "& .MuiDataGrid-cell--withRenderer": {
    fontWeight: "400",
    fontSize: "1.1rem",
    padding: "0 !important",
  },
  ".MuiDataGrid-cell:focus": {
    outline: "0",
  },
  ".MuiDataGrid-columnHeader:focus": {
    outline: "0",
  },
  ".MuiDataGrid-virtualScroller": {
    scrollbarWidth: "thin",
    borderBottom: "1px solid #545356",
    borderTop: "1px solid #545356",
  },
  ".MuiDataGrid-virtualScroller::-webkit-scrollbar": {
    height: "5px",
    width: "5px",
  },
  "& .MuiDataGrid-renderingZone": {
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: "black !important",
    zIndex: "10000 !important",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "unset !important",
    maxHeight: "none !important",
    whiteSpace: "normal",
    height: "3.59rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 !important",
    margin: "0 !important",
  },

  "& .MuiDataGrid-row": {
    // gap: "2rem",
    // maxHeight: "none !important",
    borderBottom: "1px solid #545356 !important",
    borderTop: "1px solid #545356 !important",
    cursor: "pointer",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "hidden",
  },

  ".MuiSvgIcon-root": {
    color: "white",
  },
  ".MuiDataGrid-sortIcon": {
    color: "white",
  },
  "& .MuiTablePagination-selectLabel": {
    color: "white",
    fontFamily: "Blender Pro thin",
    fontSize: "1.1rem",
    transform: "translateY(4px)",
  },
  ".MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input":
    {
      color: "white",
      border: "1px solid #545356",
      borderRadius: "0px",
      "& .MuiSvgIcon-root": {
        fill: "white",
      },
    },
  "& .MuiTablePagination-displayedRows": {
    color: "white",
    fontFamily: "Blender Pro thin",
  },
}));

export function getDeliveryLocationStatusIconCsr(status) {
  switch (status) {
    case "Not Verified":
      return (
        <Skull size={20} color="#FB5872"/>
      );
    case "Hold":
      return (
        <Warning size={20} color="#CECB6C" />
      );
    case "Verified":
      return (
        <CheckCircle size={20} color="#FEBEF2"/>
      );
    default:
      return null;
  }
}

export function getPriorityLevelIconCSR(status) {
  switch (status) {
    case PRIORITY_LEVEL.HIGH:
      return (
        <CaretDoubleUp size={20} color="red"/>
      );
    case PRIORITY_LEVEL.MEDIUM:
      return null
    case PRIORITY_LEVEL.LOW:
      return (
        <CaretDoubleDown size={20} color="red"/>
      );
    default:
      return null;
  }
}

export function getPriorityLevelTextCSR(status) {
  switch (status) {
    case PRIORITY_LEVEL.HIGH:
      return "Priority: High"
    case PRIORITY_LEVEL.MEDIUM:
      return null
    case PRIORITY_LEVEL.LOW:
      return "Priority: Low"
    default:
      return null;
  }
}

export const ErrorsComp = (varName, errorPosition, errorText) => {
  return (
    <>
      {errorPosition === varName ? (
        <>
          <span className="errText float-right">{errorText}</span>
        </>
      ) : null}
    </>
  );
};

export const InputLabelCsrLogin = ({
  label,
  varName,
  errorPosition,
  errorText,
}) => {
  return (
    <label
      style={{
        height: "1.18rem",
        width: "21.3rem",
        fontFamily: '"Zen Antique", serif',
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        color: "#ffffff",
        marginBottom: "0.5rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <span>{label}</span>
      {ErrorsComp(varName, errorPosition, errorText)}
    </label>
  );
};

export const InputButtonCsr = ({
  name,
  value,
  setValues,
  placeholder,
  className,
}) => {
  return (
    <input
      type="text"
      value={value}
      style={{
        height: "2.6rem",
        width: "21.3rem",
        backgroundColor: "transparent",
        border: "1px solid #494550",
        color: "white",
        padding: "10px",
        outline: "none",
        borderRadius: "1px",
      }}
      className={className}
      placeholder={placeholder}
      onChange={(e) =>
        setValues((prev) => ({ ...prev, [name]: e.target.value }))
      }
    />
  );
};

export const InputLabelCsr = ({ label, varName, errorPosition, errorText }) => {
  return (
    <label
      style={{
        height: "1.18rem",
        width: "21.3rem",
        fontFamily: "Blender Pro thin",
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "1.2rem",
        color: "#ffffff",
        marginBottom: "0.5rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <span>{label}</span>
      {ErrorsComp(varName, errorPosition, errorText)}
    </label>
  );
};

export const InputCompTextAreaCsr = ({ value, name, setValues, className }) => {
  return (
    <textarea
      rows="5"
      value={value}
      className={"p-2 " + className}
      style={{ height: "9.3rem" }}
      onChange={(e) =>
        setValues((prev) => ({ ...prev, [name]: e.target.value }))
      }
    />
  );
};

export const InputButtonEyeCsr = ({
  value,
  name,
  setValues,
  placeholder,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        type={showPassword ? "text" : "password"}
        value={value}
        style={{
          height: "2.6rem",
          width: "21.3rem",
          backgroundColor: "transparent",
          border: "1px solid #494550",
          color: "white",
          padding: "10px",
          paddingRight: "45px",
          paddingLeft: "10px",
          outline: "none",
          borderRadius: "1px",
        }}
        className={className}
        placeholder={placeholder}
        onChange={(e) =>
          setValues((prev) => ({ ...prev, [name]: e.target.value }))
        }
      />
      {showPassword ? (
        <Eye
          size={25}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "#00F0FF",
          }}
          onClick={togglePasswordVisibility}
        />
      ) : (
        <EyeSlash
          size={25}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "#00F0FF",
          }}
          onClick={togglePasswordVisibility}
        />
      )}
    </div>
  );
};

export const InputButtonSearchCsr = ({
  value,
  name,
  setValues,
  placeholder,
  className,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        value={value}
        style={{
          height: "2.5rem",
          width: "15.6rem",
          backgroundColor: "transparent",
          border: "1px solid #00F0FF",
          color: "white",
          padding: "10px",
          paddingRight: "45px",
          paddingLeft: "10px",
          outline: "none",
          borderRadius: "1px",
        }}
        className={className}
        placeholder={placeholder}
        onChange={(e) =>
          setValues((prev) => ({ ...prev, [name]: e.target.value }))
        }
      />

      <MagnifyingGlass
        size={20}
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          color: "#00F0FF",
        }}
      />
    </div>
  );
};

export const CartQuantityCsr = (quantity, setValues) => {
  const handleQuanChange = (type) => {
    if (type === "add") {
      quantity = quantity ? quantity + 1 : 2;
      setValues((x) => ({ ...x, quanSel: quantity }));
    } else if (type === "minus") {
      quantity = quantity && quantity !== 1 ? quantity - 1 : quantity;
      setValues((x) => ({ ...x, quanSel: quantity }));
    }
  };

  return (
    <>
      {quantity && (
        <div className="cart_quan_div_csr">
          <Button
            className="cq_minus_plus_csr"
            onClick={() => handleQuanChange("minus")}
          >
            -
          </Button>
          <div className="cq_line_csr" />
          <Button className="cq_text_csr">{quantity ? quantity : 1}</Button>
          <div className="cq_line_csr" />
          <Button
            className="cq_minus_plus_csr"
            onClick={() => handleQuanChange("add")}
          >
            +
          </Button>
        </div>
      )}
    </>
  );
};

// get spice value
export const getSpiceValue = (spiceLevel) => {
  var spiceLevelVal = 28;
  switch (spiceLevel) {
    case "No":
      spiceLevelVal = 0;
      break;
    case "Low":
      spiceLevelVal = 28;
      break;
    case "Medium":
      spiceLevelVal = 66;
      break;
    case "High":
      spiceLevelVal = 100;
      break;
    default:
      spiceLevelVal = 28;
      break;
  }
  return spiceLevelVal;
};

export const AdOrderEditDeleteMenuCsr = (
  prod,
  editFn,
  add_edit_visible_ind,
  Set_add_edit_visible,
  ind,
  itemsArr,
  setValues,
  setShowDiscountModal,
  setShowEditModifiersModal,
  orderData,
) => {
  const menuStyle = {
    border: "1px solid #545356",
    borderRadius: "0",
    backgroundColor: "black",
  };

  const dividerStyle = {
    border: "1px solid #545356",
    borderColor: "#545356",
  };

  const deleteItem = (product) => {
    const filteredArr = itemsArr.filter((item) => {
      if (product.identifierId) {
        if (product.comboId) {
          return !(
            item.comboId === product.comboId &&
            item.identifierId === product.identifierId
          );
        } else if (product.itemId) {
          return !(
            item.itemId === product.itemId &&
            item.identifierId === product.identifierId
          );
        } else if (product.customId) {
          return item.customId !== product.customId;
        }
        return true;
      } else {
        if (product.comboId) {
          return item.comboId !== product.comboId || item.identifierId;
        } else if (product.itemId) {
          return item.itemId !== product.itemId || item.identifierId;
        } else if (product.customId) {
          return item.customId !== product.customId;
        }
        return true;
      }
    });
    setValues((prev) => ({
      ...prev,
      itemsArr: filteredArr,
    }));
  };

  const setEditItem = (e) => {
    e.stopPropagation();
    setValues((x) => ({
      ...x,
      currentItem: prod,
      spiceLevel: prod?.hasSpiceLevel ? getSpiceValue(prod.spiceLevel) : null,
      modifiersSel: prod?.modifiers?.length > 0 ? prod.modifiers : null,
    }));
  };

  const setDiscountItem = (e) => {
    setValues((x) => ({
      ...x,
      currentItem: prod,
      customDiscount: {
        discountType: prod?.discount?.type || "percent",
        discountValue: prod?.discount?.value || null,
      },
    }));
  };

  var menuData = <></>;

  var showApplyDiscountButton = true; // Initialize a variable to control Apply Discount button visibility
  // Check if orderData exists and isAdminCreated is false
  // if (orderData && orderData?.isAdminCreated === false) {
  //   showApplyDiscountButton = false;
  // } else
  if (prod.hasOwnProperty("customId")) {
    showApplyDiscountButton = false;
  } else {
    showApplyDiscountButton = true;
  }

  if (
    (prod?.modifiers && prod?.modifiers.length > 0) ||
    prod.hasSpiceLevel === true
  ) {
    const buttons = [
      {
        label: (
          <Button
            className="edit_delete_text csr_little_texts_moderate "
            onClick={(e) => {
              e.stopPropagation();
              setEditItem(e);
              setShowEditModifiersModal(true);
              Set_add_edit_visible(null);
            }}
            sx={{
              color: "white !important",
              "&:hover": {
                color: "black !important",
              },
            }}
          >
            Edit Custom
          </Button>
        ),
        key: "0",
      },
      {
        type: "divider_csr",
      },
    ];

    if (showApplyDiscountButton) {
      // Only add Apply Discount button if showApplyDiscountButton is true
      buttons.push({
        label: (
          <Button
            className="edit_delete_text csr_little_texts_moderate"
            onClick={(e) => {
              e.stopPropagation();
              setDiscountItem(e);
              setShowDiscountModal(true);
              Set_add_edit_visible(null);
            }}
            sx={{
              color: "white !important",
              "&:hover": {
                color: "black !important",
              },
            }}
          >
            Apply Discount
          </Button>
        ),
        key: "1",
      });
      buttons.push({
        type: "divider_csr",
      });
    }

    buttons.push({
      label: (
        <Button
          className="edit_delete_text csr_little_texts_moderate"
          onClick={(e) => {
            e.stopPropagation(e);
            deleteItem(prod);
            Set_add_edit_visible(null);
          }}
          sx={{
            color: "white !important",
            "&:hover": {
              color: "black !important",
            },
          }}
        >
          Delete
        </Button>
      ),
      key: "2",
    });

    // menuData = <Menu items={buttons} style={menuStyle} />;
    menuData = (
      <Menu style={menuStyle}>
        {buttons.map((button, index) =>
          button.type === "divider_csr" ? (
            <Menu.Divider style={{ ...dividerStyle }} key={index} />
          ) : (
            <Menu.Item style={{ color: "#FFFFFF" }} key={button.key}>
              {button.label}
            </Menu.Item>
          ),
        )}
      </Menu>
    );
  } else if (prod?.hasSpiceLevel !== true) {
    const buttons = [];

    if (showApplyDiscountButton) {
      // Only add Apply Discount button if showApplyDiscountButton is true
      buttons.push({
        label: (
          <Button
            className="edit_delete_text csr_little_texts_moderate"
            onClick={(e) => {
              e.stopPropagation();
              setDiscountItem(e);
              setShowDiscountModal(true);
              Set_add_edit_visible(null);
            }}
            sx={{
              color: "white !important",
              "&:hover": {
                color: "black !important",
              },
            }}
          >
            Apply Discount
          </Button>
        ),
        key: "0",
      });
      buttons.push({
        type: "divider_csr",
      });
    }

    buttons.push({
      label: (
        <Button
          className="edit_delete_text csr_little_texts_moderate"
          onClick={(e) => {
            e.stopPropagation();
            deleteItem(prod);
            Set_add_edit_visible(null);
          }}
          sx={{
            color: "white !important",
            "&:hover": {
              color: "black !important",
            },
          }}
        >
          Delete
        </Button>
      ),
      key: "1",
    });

    // menuData = <Menu items={buttons} style={menuStyle} />;
    menuData = (
      <Menu style={menuStyle}>
        {buttons.map((button, index) =>
          button.type === "divider_csr" ? (
            <Menu.Divider style={{ ...dividerStyle }} key={index} />
          ) : (
            <Menu.Item style={{ color: "#FFFFFF" }} key={button.key}>
              {button.label}
            </Menu.Item>
          ),
        )}
      </Menu>
    );
  }

  const handleVisibleChange = (visible) => {
    if (!visible) {
      Set_add_edit_visible(null);
      setValues((x) => ({
        ...x,
        currentItem: prod,
        customDiscount: { discountType: "percent", discountValue: null },
        modifiersSel: null,
      }));
    }
  };

  const menuClick = (e) => {
    e.stopPropagation();
    Set_add_edit_visible(ind);
  };

  return (
    <Dropdown
      visible={add_edit_visible_ind === ind}
      overlay={menuData}
      trigger={["click"]}
      onClick={(e) => menuClick(e)}
      onVisibleChange={handleVisibleChange}
    >
      <Button>
        <DotsThree color="#FFFFFF" weight="bold" size={20} />
      </Button>
    </Dropdown>
  );
};

export const CartQuantityAdminArrCsr = (prod, itemsArr, setValues, editing) => {
  const handleQuanChange = (type) => {
    const updatedItemsArr = itemsArr.map((item) => {
      if (item.itemId && !item.identifierId) {
        if (item.itemId === prod.itemId && !prod.identifierId) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.itemId && item.identifierId) {
        if (
          item.itemId === prod.itemId &&
          item.identifierId === prod.identifierId
        ) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.comboId && !item.identifierId) {
        if (item.comboId === prod.comboId && !prod.identifierId) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.comboId && item.identifierId) {
        if (
          item.comboId === prod.comboId &&
          item.identifierId === prod.identifierId
        ) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.customId) {
        if (item.customId === prod.customId) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        }
      }
      return item;
    });
    setValues((x) => ({ ...x, itemsArr: updatedItemsArr }));
  };

  return (
    <>
      {prod && (
        <div className="cart_quan_div_csr">
          <Button
            className="cq_minus_plus_csr"
            onClick={() => handleQuanChange("minus")}
          >
            -
          </Button>
          <div className="cq_line_csr" />
          <Button className="cq_text_csr">
            {prod.quantity ? prod.quantity : 1}
          </Button>
          <div className="cq_line_csr" />
          <Button
            className="cq_minus_plus_csr"
            onClick={() => handleQuanChange("add")}
          >
            +
          </Button>
        </div>
      )}
    </>
  );
};

export const AlertErrorCsr = (errorPosition, errorText, setValues) => {
  var sev = "";
  // if (errorPosition === "success") sev = "#32BF52";
  // else if (errorPosition === "apiErr") sev = "#8943EF";
  if (errorPosition === "success") sev = "success";
  else if (errorPosition === "apiErr") sev = "error";
  return (
    (errorPosition === "mainErr" ||
      errorPosition === "apiErr" ||
      errorPosition === "success") && (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={
          errorPosition === "mainErr" ||
          errorPosition === "apiErr" ||
          errorPosition === "success"
        }
        autoHideDuration={3000}
        onClick={() =>
          setValues((val) => ({ ...val, errorPosition: "", errorText: "" }))
        }
        onClose={() =>
          setValues((val) => ({ ...val, errorPosition: "", errorText: "" }))
        }
        message="I love snacks"
        key={"top+center"}
        style={{ borderRadius: 0 }}
      >
        <Alert
          variant="filled"
          severity={sev}
          style={{
            fontFamily: "Blender Pro Thin",
            fontSize: "1rem",
            fontWeight: "bold",
            // backgroundColor: sev, // Use the sev variable as background color
            // color: "#FFFFFF",
          }}
          className="rounded-0"
        >
          {errorText}
        </Alert>
      </Snackbar>
    )
  );
};

export const ReactSelectDropCsr = (
  varValue,
  varName,
  handleChangeFun,
  selectData,
) => {
  const handleValue = (e) => {
    handleChangeFun((mainValues) => ({ ...mainValues, [varName]: e.value }));
  };

  return (
    <Select
      styles={customStyles}
      maxMenuHeight={"200px"}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      options={selectData?.map((item) =>
        item._id ? { label: item.name, value: item._id } : item,
      )}
      value={selectData
        ?.filter((option) => option._id === varValue)
        ?.map((item) => ({ label: item.name, value: item._id }))}
      name={varName}
      onChange={(e) => handleValue(e)}
      className="mb-2"
    />
  );
};

export const MUIMenuTwo = ({ EditFn, DeleteFn }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    EditFn();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="del_assign_btn ml-5"
      >
        <DotsThree size={25} weight="bold" color="#FFF" />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            width: "4rem",
            backgroundColor: "black",
            color: "white",
            border: "1px solid #545356 ",
          },
        }}
      >
        <MenuItem
          onClick={handleEdit}
          sx={{
            backgroundColor: "black",
            color: "white",
            fontFamily: "Blender Pro thin",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Edit
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem> */}
      </MUIMenu>
    </div>
  );
};
