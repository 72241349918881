import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthenticatedCsrAdmin,
  POSTAPI_CSR_ADMIN,
  isAuthenticatedCsrAdmin,
} from "../../Auth/Index";
import { EncryptPasswordAES } from "../../Components/Common";
import {
  AlertErrorCsr,
  InputButtonCsr,
  InputButtonEyeCsr,
  InputLabelCsrLogin,
} from "../../Components/CommonCsr";
import { ValidateLogin } from "../../Components/Validate_Admin";
import { getS3Image } from "../../Components/s3";
import "../../css/Csr/csr_login.css";
import { SvgButtonCSR } from "./CsrLayout";

export const CsrLogin = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
    errorPosition: "",
    errorText: "",
    loading: false,
  });
  const { email, password, errorPosition, errorText, loading } = values;

  useEffect(() => {
    if (isAuthenticatedCsrAdmin()) navigate("/csr/dashboard");
  }, [navigate]);

  const loginSubmit = async () => {
    if (loading) return;
    var validated = await ValidateLogin(email, password, setValues);
    if (validated) {
      setValues((prev) => ({ ...prev, loading: true }));
      var encrypted_pass = EncryptPasswordAES(password);
      var send_data = {
        contact: email,
        password: encrypted_pass,
      };
      POSTAPI_CSR_ADMIN("/employee/csrLogin", send_data).then((res) => {
        if (res.success) {
          AuthenticatedCsrAdmin(res.data);
          navigate("/csr/dashboard");
        }
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  return (
    <div className="login">
      <div className="items">
        <div className="items_content">
          <div className="heading">
            <img className="logo" alt="Logo" src={getS3Image("csrLogo.svg")} />
            <div className="heading_text">管制センター</div>
          </div>
          <form className="form" onSubmit={loginSubmit}>
            <div className="text-wrapper">ログイン</div>
            <div className="inputs">
              <div className="button_container">
                <InputLabelCsrLogin label="ユーザーID" />
                <InputButtonCsr
                  name="email"
                  value={email}
                  setValues={setValues}
                />
              </div>
              <div className="button_container">
                <InputLabelCsrLogin label="パスワード" />
                <InputButtonEyeCsr
                  name="password"
                  value={password}
                  setValues={setValues}
                />
              </div>
            </div>

            {loading ? (
              <>
                <div
                  type="submit"
                  className="inputs_submit_button d-flex justify-content-center"
                >
                  <CircularProgress sx={{ color: "#00F0FF" }} size={30} />
                </div>
              </>
            ) : (
              <>
                <div
                  type="submit"
                  className="inputs_submit_button"
                  onClick={() => loginSubmit()}
                >
                  {SvgButtonCSR({text:'LOGIN'})}
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      <div className="items">
        <img className="img_car" alt="car" src={getS3Image("car.png")} />
      </div>
      {AlertErrorCsr(errorPosition, errorText, setValues)}
    </div>
  );
};
