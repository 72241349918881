import CryptoJS from "crypto-js";
import { API, API_KEY } from "../Auth/Config";

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_API_TOKEN_ENC,
  ).toString();
};

export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(
    ciphertext,
    process.env.REACT_APP_API_TOKEN_ENC,
  );
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export const isAuthenticated = () => {
  try {
    if (typeof window == "undefined") {
      return false;
    }
    if (localStorage?.getItem("_nova_foods") == null) {
      return false;
    }
    if (localStorage.getItem("_nova_foods")) {
      var item = JSON.parse(localStorage.getItem("_nova_foods"));
      var dec = decryptData(item);
      if (!dec?.userId) return false;
      return dec;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const isAuthenticatedAdmin = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("_nova_foods_ad") == null) {
    return false;
  }
  if (localStorage.getItem("_nova_foods_ad")) {
    var item = JSON.parse(localStorage.getItem("_nova_foods_ad"));
    var dec = decryptData(item);
    return dec;
  } else {
    return false;
  }
};

export const isAuthenticatedCsrAdmin = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("_nova_foods_csr_ad") == null) {
    return false;
  }
  if (localStorage.getItem("_nova_foods_csr_ad")) {
    var item = JSON.parse(localStorage.getItem("_nova_foods_csr_ad"));
    var dec = decryptData(item);
    return dec;
  } else {
    return false;
  }
};

export const isAuthenticatedKDS = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("_nova_foods_kds") == null) {
    return false;
  }
  if (localStorage.getItem("_nova_foods_kds")) {
    var item = JSON.parse(localStorage.getItem("_nova_foods_kds"));
    var dec = decryptData(item);
    return dec;
  } else {
    return false;
  }
};

export const RememberMe = (path, data) => {
  if (typeof window !== "undefined") {
    var enc = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_API_TOKEN_ENC,
    ).toString();
    localStorage.setItem(path, JSON.stringify(enc));
  }
};

export const RememberMeRemove = (path) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(path);
  }
};

export const DeleteUser = async (path) => {
  const { jwtToken } = isAuthenticated();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const isRemKDS = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("_nova_foods_kds_rem") == null) {
    return false;
  }
  if (localStorage.getItem("_nova_foods_kds_rem")) {
    var item = JSON.parse(localStorage.getItem("_nova_foods_kds_rem"));
    var dec = decryptData(item);
    return dec;
  } else {
    return false;
  }
};

export const isRemAdmin = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("_nova_foods_ad_rem") == null) {
    return false;
  }
  if (localStorage.getItem("_nova_foods_ad_rem")) {
    var item = JSON.parse(localStorage.getItem("_nova_foods_ad_rem"));
    var dec = decryptData(item);
    return dec;
  } else {
    return false;
  }
};

export const isRem = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("_nova_foods_rem") == null) {
    return false;
  }
  if (localStorage.getItem("_nova_foods_rem")) {
    var item = JSON.parse(localStorage.getItem("_nova_foods_rem"));
    var dec = decryptData(item);
    return dec;
  } else {
    return false;
  }
};

// market logout
export const Logout = () => {
  localStorage.removeItem("_nova_foods");
  // window.FB.getLoginStatus(({ authResponse }) => {
  //     if (authResponse) window.FB.logout()
  // })
  return;
};

export const LogoutAdmin = () => {
  localStorage.removeItem("_nova_foods_ad");
  // window.FB.logout()
  return;
};

export const LogoutCsrAdmin = () => {
  localStorage.removeItem("_nova_foods_csr_ad");
  // window.FB.logout()
  return;
};

export const LogoutKDS = () => {
  localStorage.removeItem("_nova_foods_kds");
  // window.FB.logout()
  return;
};

export const AuthenticateNova = (data) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("_nova_foods", JSON.stringify(encryptData(data)));
  }
};

export const AuthenticateNovaAdmin = (data) => {
  if (typeof window !== "undefined") {
    var enc = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_API_TOKEN_ENC,
    ).toString();
    localStorage.setItem("_nova_foods_ad", JSON.stringify(enc));
  }
};

export const AuthenticatedCsrAdmin = (data) => {
  if (typeof window !== "undefined") {
    var enc = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_API_TOKEN_ENC,
    ).toString();
    localStorage.setItem("_nova_foods_csr_ad", JSON.stringify(enc));
  }
};

export const AuthenticateNovaKDS = (data) => {
  if (typeof window !== "undefined") {
    var enc = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_API_TOKEN_ENC,
    ).toString();
    localStorage.setItem("_nova_foods_kds", JSON.stringify(enc));
  }
};

export const GetAPI = async (path) => {
  try {
    const response = await fetch(`${API}${path}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const GetAPIUser = async (path) => {
  const { jwtToken } = isAuthenticated();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const GetAPIAdmin = async (path) => {
  const { jwtToken } = isAuthenticatedAdmin();

  try {
    const response = await fetch(`${API}${path}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const GetAPIKDS = async (path) => {
  const { jwtToken } = isAuthenticatedKDS();

  try {
    const response = await fetch(`${API}${path}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const GetAPICsr = async (path) => {
  const { jwtToken } = isAuthenticatedCsrAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const GETAPI_OrderTypes = async (path) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const GETAPI_OrderTypesCsr = async (path) => {
  const { jwtToken } = isAuthenticatedCsrAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_CreateInfoAdmin = async () => {
  const { userId, jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}/item/createInfo`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ userId }),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_CreateInfoCsrAdmin = async () => {
  const { userId, jwtToken } = isAuthenticatedCsrAdmin();
  try {
    const response = await fetch(`${API}/item/createInfo`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ userId }),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPIKDS = async (path, body) => {
  const { jwtToken } = isAuthenticatedKDS();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_otp = async (path, body) => {
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      },
      body: JSON.stringify(body),
    });
    return await response;
  } catch (err) {
    return err;
  }
};

export const POSTAPIAddToAfterReg = async (path, body, jwtTokenVal) => {
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtTokenVal}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_USER = async (path, body) => {
  const { jwtToken } = isAuthenticated();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_ADMIN = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_CSR_ADMIN = async (path, body) => {
  const { jwtToken } = isAuthenticatedCsrAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_ADMIN_promise = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_form = async (path, body) => {
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
      },
      body: body,
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_formUser = async (path, body) => {
  const { jwtToken } = isAuthenticated();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: body,
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const POSTAPI_formAdmin = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: body,
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const PUTAPI = async (path, body) => {
  try {
    const response = await fetch(`${API}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const PUTAPIAdmin = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const PUT_API_form = async (path, body) => {
  try {
    const response = await fetch(`${API}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
      },
      body: body,
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const PUT_API_formAdmin = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: body,
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const DELETEAPI = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

export const DELETEAPIAdmin = async (path, body) => {
  const { jwtToken } = isAuthenticatedAdmin();
  try {
    const response = await fetch(`${API}${path}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

// add to after login
export const POSTAPI_USER_afterlogin = async (path, body, tokenData) => {
  try {
    const response = await fetch(`${API}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${tokenData}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};

// conversion API
export const FB_ConversionAPI = async (body) => {
  try {
    if (window.location.href.includes("localhost")) return;
    const response = await fetch(
      `https://graph.facebook.com/v15.0/2082546562133049/events?access_token=EAAMQTeNCQwUBAHqZB1OfzlZBdtTPBWUWvtiwFIEBcaOkPuZBdfEvyO3SjHPBlssGhBQGo1FKL2KLAZA58PZAjQnX496TallUwKhZBhA8Iyqzl4eetDYsqKmAXzRfQH9010NoTNEPNTV6xDIVv3gDxMWcH3fXvAKZC4mMGAsZAULHCVmdhzzS6Az0`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    );
    return await response.json();
  } catch (err) {
    return err;
  }
};
