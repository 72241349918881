export const offerTypes = [
  { _id: "Offer", name: "Offer" },
  { _id: "Promo", name: "Promo" },
];

export const offerGroupValues = [
  { _id: "All", name: "All" },
  { _id: "Brand", name: "Brand" },
  { _id: "Category", name: "Category" },
  { _id: "Division", name: "Division" },
  { _id: "Item", name: "Item" },
];

export const designationData = [
  { _id: "Driver", name: "Driver" },
  { _id: "Admin", name: "Admin" },
];

export const ENTITY_TYPE = {
  APP: "App",
  ATTENDANCE: "Attendance",
  BRAND: "Brand",
  CART: "Cart",
  CATEGORY: "Category",
  COOKING_STATS: "Cooking Stats",
  COMBO: "Combo",
  DASHBOARD: "Dashboard",
  DESIGNATION: "Designation",
  EMPLOYEE: "Employee",
  HOMEPAGE: "Homepage",
  INVENTORY: "Inventory",
  ITEM: "Item",
  LOCATION: "Location",
  MARKETPLACE: "Marketplace",
  MEMBERSHIP: "Membership",
  MODIFIER: "Modifier",
  NOTIFICATION: "Notification",
  OFFER: "Offer",
  ORDER: "Order",
  ORDER_STATS: "Order Stats",
  REPORT: "Report",
  RIDER_STATS: "Rider Stats",
  SETTING: "Setting",
  SHIFT: "Shift",
  SUBSCRIPTION: "Subscription",
  USER: "User",
};

export const ORDER_STATUS = {
  DRAFT: "Draft",
  QUEUE: "Queue",
  COOKING: "Cooking",
  NOT_ASSIGNED: "Not Assigned",
  PENDING: "Pending",
  PICKED_UP: "Picked Up",
  COMPLETED: "Completed",
  CANCELED: "Canceled",
  FAILED: "Failed",
};

export const PRIORITY_LEVEL = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const DELIVERY_LOCATION_STATUS = {
  VERIFIED: "Verified",
  HOLD: "Hold",
  NOT_VERIFIED: "Not Verified",
};

export const PAYMENT_METHODS = {
  FONEPAY: "Fonepay",
  // ESEWA: "Esewa",
  // KHALTI: "Khalti",
  CASH: "Cash",
};

export const ACTIONS = {
  CREATE_OWN: "create_own",
  CREATE_ALL: "create_all",
  READ_OWN: "read_own",
  READ_ALL: "read_all",
  UPDATE_OWN: "update_own",
  UPDATE_ALL: "update_all",
  DELETE_OWN: "delete_own",
  DELETE_ALL: "delete_all",
};

export const ACTIONS_ARR = [
  {
    resource: "Dashboard",
    actions: ["create_own"],
  },
  {
    resource: "Brand",
    actions: ["create_own"],
  },
  {
    resource: "Cart",
    actions: ["create_own"],
  },
  {
    resource: "Category",
    actions: ["create_own"],
  },
  {
    resource: "Combo",
    actions: ["create_own"],
  },
  {
    resource: "Designation",
    actions: ["create_own"],
  },
  {
    resource: "Employee",
    actions: ["create_own"],
  },
  {
    resource: "Homepage",
    actions: ["create_own"],
  },
  {
    resource: "Inventory",
    actions: ["create_own"],
  },
  {
    resource: "Item",
    actions: ["create_own"],
  },
  {
    resource: "Location",
    actions: ["create_own"],
  },
  {
    resource: "Modifier",
    actions: ["create_own"],
  },
  {
    resource: "Notification",
    actions: ["create_own"],
  },
  {
    resource: "Offer",
    actions: ["create_own"],
  },
  {
    resource: "Order",
    actions: ["create_own"],
  },
  {
    resource: "Setting",
    actions: ["create_own"],
  },
  {
    resource: "User",
    actions: ["create_own"],
  },
];
